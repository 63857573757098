import { ComponentSdkFactory } from '@wix/thunderbolt-platform-types'
import { ModelsAPI } from '../types'
import { WixSelector } from '../wixSelector'
import instancesObjectFactory from '../instancesFactory'
import { getDisplayedId, getItemId, isDisplayedOnly } from '@wix/thunderbolt-commons'

export function GroupSdkFactory({ wixSelector, modelsApi }: { wixSelector: WixSelector; modelsApi: ModelsAPI }): ComponentSdkFactory {
	return ({ controllerCompId, compId, metaData: { connection } }) => {
		const getGroupItemCompId = (groupItemCompId: string) => (isDisplayedOnly(compId) ? getDisplayedId(groupItemCompId, getItemId(compId)) : groupItemCompId)
		const compFromStructure = modelsApi.getStructureModelComp(compId)
		if (!compFromStructure) {
			return null
		}
		const instances = compFromStructure.components.map((groupItemCompId: string) => {
			return wixSelector.getInstance({
				controllerCompId,
				compId: getGroupItemCompId(groupItemCompId),
				connection,
				role: modelsApi.getRoleForCompId(groupItemCompId, controllerCompId) || '',
				compType: modelsApi.getCompType(groupItemCompId) || ''
			})
		})
		return instancesObjectFactory(instances)
	}
}
