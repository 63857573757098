import _ from 'lodash'
import { EVENT_TYPES_MAP } from './constants'
import { getFullId, isDisplayedOnly, createPromise } from '@wix/thunderbolt-commons'
import { ComponentSdksManager, EventHandlers, ModelsAPI } from './types'
import { IControllerEvents } from './ControllerEvents'
import { PlatformLogger } from '@wix/thunderbolt-symbols'
import { WixSelector } from './wixSelector'

export type StaticEventsManager = {
	setStaticEventsCallbacks: (eventHandlers: EventHandlers) => void
	triggerStaticEventsHandlers: () => void
}

export function CreateStaticEventsManager({
	modelsApi,
	controllerEventsFactory,
	wixSelector,
	logger,
	componentSdksManager
}: {
	modelsApi: ModelsAPI
	controllerEventsFactory: IControllerEvents
	wixSelector: WixSelector
	logger: PlatformLogger
	componentSdksManager: ComponentSdksManager
}): StaticEventsManager {
	const { resolver: staticEventCallbacksResolver, promise: staticEventCallbacksPromise } = createPromise<EventHandlers>()
	const { resolver: triggerStaticEventsHandlers, promise: waitForStaticEventsToBeTriggered } = createPromise<void>()
	function reportStaticEventsError(errorMessage: string, extra: any) {
		logger.captureError(new Error(`WixCode Static Events Error: ${errorMessage}`), {
			tags: {
				staticEvents: true
			},
			extra
		})
	}
	function getCompIdFromEventOrModels(compIdFromEvent: string, sourceId: string) {
		const compId = isDisplayedOnly(compIdFromEvent) ? getFullId(compIdFromEvent) : compIdFromEvent
		if (modelsApi.getStructureModelComp(compId)) {
			return compId
		}
		if (modelsApi.getStructureModelComp(sourceId)) {
			return sourceId
		}
		const connection = _.head(modelsApi.getConnectionsByCompId('wixCode', compId))
		if (connection) {
			return connection.compId
		}
	}

	function createDynamicEvent({ compId, viewerEvent, handler }: { compId: string; viewerEvent: string; handler: Function }) {
		const role = modelsApi.getRoleForCompId(compId, 'wixCode') as string
		const compType = modelsApi.getCompType(compId) as string
		const sdkInstance = wixSelector.getInstance({ controllerCompId: 'wixCode', compId, role, compType }) as any
		if (!_.isFunction(sdkInstance[viewerEvent])) {
			reportStaticEventsError('viewerEvent does not exists in sdkInstance', {
				compId,
				viewerEvent,
				sdkInstanceKeys: Object.keys(sdkInstance)
			})
			return
		}
		sdkInstance[viewerEvent]((...args: any) => waitForStaticEventsToBeTriggered.then(() => handler(...args)))
	}

	function registerStaticEvents(callbacks: EventHandlers) {
		const staticEvents = modelsApi.getStaticEvents()
		staticEvents.forEach(({ compId: eventCompId, eventType, callbackId: fnName, sourceId }) => {
			const viewerEvent = EVENT_TYPES_MAP[eventType]
			const compId = getCompIdFromEventOrModels(eventCompId, sourceId)
			if (!compId) {
				reportStaticEventsError('could not find component in the given static event behavior data', {
					eventCompId,
					eventType,
					fnName
				})
				return
			}
			const handler = callbacks[fnName]
			if (!handler) {
				console.warn(`function ${fnName} is registered as a static event handler but is not exported from the page code. Please remove the static event handler or export the function.`)
				return
			}
			if (viewerEvent) {
				// if the event is in the list of viewer events (onClick, onMouseEnter, etc..) we register it as a dynamic event ($w.onMouseEnter())
				createDynamicEvent({ compId, viewerEvent, handler })
			} else if (modelsApi.isController(compId)) {
				// might be a custom events of the controller, for example "onDatasetReady" - then we register it as a controller event.
				controllerEventsFactory.createScopedControllerEvents(compId).on(eventType, handler)
			} else {
				reportStaticEventsError('eventType is not found in viewerEvents', {
					eventType,
					compId,
					fnName,
					EVENT_TYPES_MAP
				})
			}
		})
	}

	Promise.all([staticEventCallbacksPromise, componentSdksManager.waitForSdksToLoad()]).then(([staticEventCallbacks]) => registerStaticEvents(staticEventCallbacks))

	return {
		setStaticEventsCallbacks(callbacks) {
			staticEventCallbacksResolver(callbacks)
		},
		triggerStaticEventsHandlers: () => triggerStaticEventsHandlers()
	}
}
